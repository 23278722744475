import { Column, Image } from "../common";
import { HERO_LOGO } from "../../constants";
import { Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { useHomeScrollContext } from '../../contexts'
import { AlohaAnimation } from '../../util'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { isMobile } from 'react-device-detect'
// add

export default function Hero() {
	const [holders, setHolders] = useState('-10')
	const containerRef = useRef<HTMLDivElement>(null)
	const startTime = useRef<number | null>(null)
	const duration = 4000

	const { isLoading, error, data } = useQuery(
		'holderAmount',
		async () => {
			try {
				const response = await fetch(
					'https://api.sharetheshaka.xyz/api/holders'
				)
				return response.json()
			} catch (error) {
				console.error('Error refetching holders')
			}
		},
		{
			refetchInterval: 3000,
		}
	)

	const updateCount = (timestamp: number) => {
		if (!startTime.current) {
			startTime.current = timestamp
		}

		const elapsed = timestamp - startTime.current
		const progress = Math.min(elapsed / duration, 1)
		const value = Math.floor((data?.holderAmount || 55921) * progress)

		setHolders(value.toLocaleString())

		if (progress < 1) {
			requestAnimationFrame(updateCount)
		}
	}

	useEffect(() => {
		if (data?.holderAmount > 0 && !isMobile) {
			updateCounter({
				containerRef,
				text: data.holderAmount.toLocaleString(),
			})
		} else if (isMobile && data?.holderAmount !== null) {
			const animationFrameId = requestAnimationFrame(updateCount)

			return () => cancelAnimationFrame(animationFrameId)
		}
	}, [data])

	useEffect(() => {
		const initialFetch = async () => {
			try {
				const response = await fetch(
					'https://api.sharetheshaka.xyz/api/holders'
				)
				if (!response.ok) {
					throw new Error('Network response was not ok')
				}
				const data = await response.json()
				const totalholders = await data.holderAmount
				setHolders(totalholders.toLocaleString())
			} catch (error) {
				console.error('Error fetching holders')
			}
		}

		initialFetch()
	}, [])

	return (
		<Column
			sx={{ py: '0px' }}
			alignItems="center"
			textAlign="center"
			spacing={4}
		>
			<Typography variant="h2" style={{ margin: '-10px' }}>
				Let's #ShareTheShaka with 1 million people
			</Typography>

			<Logo />
			<div style={{ marginTop: '-50px', fontSize: '30px' }}>
				<Typography variant="h1">
					{parseInt(holders) > -9 ? (
						isMobile ? (
							<div>{holders}</div>
						) : (
							<Counter
								containerRef={containerRef}
								text={holders}
							/>
						)
					) : isMobile ? (
						<div>46,083</div>
					) : (
						<Counter containerRef={containerRef} text="47,183" />
					)}
					SHAKAS SHARED
				</Typography>
			</div>

			{/* <Button
            sx={{ width: "max-content" }}
            onClick={() => window.open("https://explorer.solana.com/address/7")}
          >
            Share the Shaka
          </Button> */}

			<ArrowPrompt />
		</Column>
	)
}


interface CounterProps {
	containerRef: React.RefObject<HTMLDivElement>
	text: string
	speed?: number
}

const Counter: React.FC<CounterProps> = ({
	containerRef,
	text,
	speed = 4000,
}) => {
	useEffect(() => {
		if (containerRef.current) {
			const number = text
			containerRef.current.setAttribute('data-number', number)
			containerRef.current.textContent = ''

			const numChars = number.split('')
			const numArray: number[] = []
			const setOfNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

			for (let i = 0; i < numChars.length; i++) {
				const char = numChars[i]
				if (setOfNumbers.includes(parseInt(char, 10))) {
					const digitCon = document.createElement('span')
					digitCon.classList.add('digit-con')

					const digit = document.createElement('span')
					digit.classList.add(`digit${numArray.length}`)
					digit.innerHTML =
						'0<br>1<br>2<br>3<br>4<br>5<br>6<br>7<br>8<br>9<br>'

					digitCon.appendChild(digit)
					containerRef.current.appendChild(digitCon)

					numArray[numArray.length] = parseInt(char, 10)
				} else {
					const span = document.createElement('span')
					span.classList.add('comma')
					span.textContent = char
					containerRef.current.appendChild(span)
				}
			}

			requestAnimationFrame(() => {
				if (containerRef.current) {
					const digitConElements = Array.from(
						containerRef.current.querySelectorAll('.digit-con')
					)
					const firstDigitCon = digitConElements[0] as HTMLElement
					const increment = firstDigitCon?.offsetHeight || 0

					for (let i = 0; i < numArray.length; i++) {
						const digitConElement = digitConElements[i]
						if (digitConElement) {
							const digitElement =
								digitConElement.firstChild as HTMLElement
							const animationDuration = Math.round(
								speed / (1 + i * 0.333)
							)
							digitElement.animate(
								[
									{ transform: `translateY(0)` },
									{
										transform: `translateY(-${
											increment * numArray[i]
										}px)`,
									},
								],
								{
									duration: animationDuration,
									fill: 'forwards',
								}
							)
						}
					}
				}
			})
		}
	}, [text, speed])

	return <div ref={containerRef} />
}

function findChangedDigits(currentNumber: string, newNumber: string) {
	const changedDigits = []
	for (
		let i = 0;
		i < Math.max(currentNumber.length, newNumber.length);
		i++
	) {
		const currentDigit = currentNumber[i] || 0
		const newDigit = newNumber[i] || 0
		if (currentDigit !== newDigit) {
			changedDigits.push(i)
		}
	}
	return changedDigits
}

function updateCounter({ containerRef, text, speed = 2000 }: CounterProps) {
	if (containerRef.current) {
		const digitConElements = Array.from(
			containerRef.current.querySelectorAll('.digit-con')
		)
		const currentNumber = containerRef.current?.dataset.number || '0'

		const changedDigits = findChangedDigits(currentNumber, text)

		if (changedDigits.length > 0) {
			containerRef.current!.dataset.number = text
			const firstDigitCon = digitConElements[0] as HTMLElement
			const increment = firstDigitCon?.offsetHeight || 0

			changedDigits.forEach((index) => {
				const digitConElement = digitConElements[index - 1]
				if (digitConElement?.firstChild) {
					const digitElement =
						digitConElement.firstChild as HTMLElement
					const animationDuration = Math.round(
						speed / (1 + index * 0.333)
					)

					digitElement.animate(
						[
							{ transform: `translateY(0)` },
							{
								transform: `translateY(-${
									increment * Number(text[index])
								}px)`,
							},
						],
						{
							duration: animationDuration,
							fill: 'forwards',
						}
					)
				}
			})
		}
	}
}

function Logo() {
	const { scrollTo } = useHomeScrollContext()

	return (
		<Stack sx={{ cursor: 'pointer' }} onClick={scrollTo.firstOnPage}>
			<Image
				src={HERO_LOGO}
				mobileHeight="100px"
				height="350px"
				width="350px"
				sx={{
					':hover': {
						animation: `${AlohaAnimation} 0.5s linear infinite alternate`,
					},
				}}
				variant="fixed-height"
			/>
		</Stack>
	)
}

function ArrowPrompt() {
  const { scrollTo } = useHomeScrollContext();
  return (
    <Button
      sx={{ textDecoration: "underline" }}
      onClick={scrollTo.firstOnPage}
      variant="text"
    >
      Share the Shaka
    </Button>
  );
}
