const SHDW_URL = "";
export const LOGO_TEXT = `${SHDW_URL}/logo_text.png`;
export const HERO_LOGO = `${SHDW_URL}/sharetheshaka.png`;

export const LOGO_SQUARE = `${SHDW_URL}/sharetheshaka.png`;
export const LOGO_SHAKA = `${SHDW_URL}/sharetheshaka.png`;

export const SHAKAIMG = `${SHDW_URL}/palm.png`;
export const ELEMENTS_LOGO =
  "https://pbs.twimg.com/media/GGLsAmzWoAA4nP5?format=jpg&name=large";


export const TWEET_LINK = "https://twitter.com/assetdash/status/1757407377765130469";
