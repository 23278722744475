export const BASE_EXPLORER = 'https://basescan.org/address/'
export const CLAIM_LINKDROP_LINK =
	'https://send.sharetheshaka.xyz/#/create/8453/0x478e03D45716dDa94F6DbC15A633B0D90c237E2F?amount=0&w=coinbase_wallet&theme=sassy'
export const BUY_LINK =
	'https://app.uniswap.org/swap?outputCurrency=0x478e03D45716dDa94F6DbC15A633B0D90c237E2F&chain=base'
export const TWITTER_LINK = 'https://x.com/ShareTheShaka'
export const BRAND_LINK = '#' // Placeholder
export const TELEGRAM_LINK = 'https://t.me/+ig8yWvW7f0kxOTI8'
export const DEX_SCREENER =
	'https://dexscreener.com/base/0xba8484d1bc98938a03f09aebd97e9bf7b424c78d'
export const DEX_TOOLS =
	'https://www.dextools.io/app/en/base/pair-explorer/0xba8484d1bc98938a03f09aebd97e9bf7b424c78d?t=1712418190467'
export const DISCORD_LINK = 'https://discord.gg/sassy'
export const INSTAGRAM_LINK = 'https://instagram.com/ShareShaka'