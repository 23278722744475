import { Hero,Mom, About, Tokenomics } from "../home";
import { MaxWidthWrapper, Page } from "../Page";
import { SPACING_PX_VAL } from "../theme";

const spacing = 8;
export default function Home() {
  return (
    <Page spacing={spacing}>
      <MaxWidthWrapper>
        <Hero />
      </MaxWidthWrapper>

      <Mom />
      <MaxWidthWrapper>
      {/* <FadeSlides /> */}
      <About />
      </MaxWidthWrapper>

      <Tokenomics />

      


    </Page>
  );
}
