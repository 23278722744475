import './App.css'
import Providers from './components/Providers'
import Routes from './components/Routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import OneSignal from 'react-onesignal'
import { useEffect } from 'react'

const queryClient = new QueryClient()

function App() {
	useEffect(() => {
		OneSignal.init({
			appId: 'f2a07f47-9fef-4f0e-ad94-c09c68223440',
		}).then(() => {
			OneSignal.Slidedown.promptPush()
		})
	}, [])

	return (
		<QueryClientProvider client={queryClient}>
			<Providers>
				<Routes />
			</Providers>
		</QueryClientProvider>
	)
}

export default App
