import { Button, Link, Stack, Typography, Tooltip } from "@mui/material";
import { useHomeScrollContext } from "../../contexts/";
import { MaxWidthWrapper } from "../Page";
import { Column, MediaStack, Row, Image } from "../common";
import { DARK_COlORS, FONT_SIZES, FULL_HEIGHT_WITH_NAV } from "../theme";
import { isMobile } from 'react-device-detect'

import { LIGHT_SHADOW, formatAddress } from '../theme'
import {
	MEDIA,
	SHAKAIMG,
	MINT,
	NFT_MINT,
	TWEET_LINK,
	CLAIM_LINKDROP_LINK,
	BASE_EXPLORER,
	DEX_SCREENER,
	DEX_TOOLS,
} from '../../constants'

export default function Tokenomics() {
	const { tokenomicsRef } = useHomeScrollContext()
	return (
		<Stack ref={tokenomicsRef} sx={{ width: '100%' }}>
			<Stack
				sx={{
					alignItems: 'center',
					width: '100%',
					justifyContent: 'center',
					minHeight: FULL_HEIGHT_WITH_NAV,
					background:
						'linear-gradient(270deg, #6e4620 40.22%, #2c1400 82.92%)',
				}}
			>
				<MaxWidthWrapper>
					<MediaStack
						spaceBetweenRow
						sxAbove={{ alignItems: 'flex-start' }}
						spacing={0}
						sx={{ marginX: 'auto' }}
					>
						<Column spacing={6}>
							<Column>
								<Typography
									variant="h1"
									sx={{ color: DARK_COlORS.body }}
								>
									Tokenomics
								</Typography>
								<Typography
									sx={{ color: DARK_COlORS.caption }}
								>
									Total supply of 420,000,000. 100% of
									proceeds from SHAKA NFT mint used to fund
									LP.
								</Typography>
							</Column>
							<TokenLinks />
							<Breakdown />
						</Column>
					</MediaStack>
				</MaxWidthWrapper>
			</Stack>
		</Stack>
	)
}

const TOKENS = [{ address: MINT, description: 'Token Address' }]
function TokenLinks() {
	return (
		<MediaStack query={MEDIA.SM} spacing={3}>
			{TOKENS.map(({ address, description }, i) => (
				<Column key={i}>
					<Typography
						color="text.secondary"
						sx={{ color: DARK_COlORS.body }}
					>
						{' '}
						{description}{' '}
					</Typography>
					<Link
						href={`${BASE_EXPLORER}/${address}`}
						color="text.primary"
						sx={{
							textTransform: 'none',
							textDecorationColor: ({ palette: { text } }) =>
								text.primary,
							color: DARK_COlORS.body,
						}}
						variant="h3"
					>
						{formatAddress(address)}
					</Link>
				</Column>
			))}
			{isMobile ? (
				<Column>
					<AddTokenButton />
					<Button
						sx={{
							width: 'max-content',
							marginX: '5px',
							marginTop: '2vh',
						}}
						onClick={() => window.open(DEX_SCREENER)}
					>
						Dex Screener
					</Button>
					<Button
						sx={{
							width: 'max-content',
							marginX: '5px',
							marginTop: '2vh',
						}}
						onClick={() => window.open(DEX_TOOLS)}
					>
						Dex Tools
					</Button>
				</Column>
			) : (
				<Row>
					<AddTokenButton />
					<Button
						sx={{
							width: 'max-content',
							marginX: '5px',
							marginTop: '2vh',
						}}
						onClick={() => window.open(DEX_SCREENER)}
					>
						Dex Screener
					</Button>
					<Button
						sx={{
							width: 'max-content',
							marginX: '5px',
							marginTop: '2vh',
						}}
						onClick={() => window.open(DEX_TOOLS)}
					>
						Dex Tools
					</Button>
				</Row>
			)}
		</MediaStack>
	)
}

function Breakdown() {
  return (
    <Row spacing={0}>

<Column spacing={1} gap={0}>			
      <Image
				src={"/shaka-breakdown.png"}
				mobileHeight="90px"
        mobileWidth="90px"
				height="350px"
				width="350px"
				variant="fixed-height"
			/></Column>
      <Column spacing={1} gap={0}>
      <TokenomicsDescription description="Airdrop NFT Communities" allocation={30} />
      <TokenomicsDescription description="SHAKA NFT Holders" allocation={30} />
      <TokenomicsDescription description="Liquidity Pool" allocation={30} />
      <TokenomicsDescription description="Marketing/Team/Devs" allocation={10} />
    </Column>

    </Row>
  );
}

const AddTokenButton = () => {
  let injectedProvider = false

  if (typeof window.ethereum !== 'undefined') {
		injectedProvider = true
  }

  const isMetaMask = injectedProvider ? window.ethereum?.isMetaMask : false
  const tokenAddress = '0x478e03D45716dDa94F6DbC15A633B0D90c237E2F'
  const tokenSymbol = '$SHAKA'
  const tokenDecimals = 18
  const tokenImage = 'https://sharetheshaka.xyz/sharetheshaka-whitebg.png'

  const handleAddToken = async () => {
		try {
			if (isMetaMask && window.ethereum !== undefined) {
				await window.ethereum.request({
					method: 'wallet_watchAsset',
					params: {
						type: 'ERC20',
						options: {
							address: tokenAddress,
							symbol: tokenSymbol,
							decimals: tokenDecimals,
							image: tokenImage,
						},
					},
				})
			} else {
				//alert('MetaMask is not installed. Please install it to use this feature.');
			}
		} catch (error) {
			//console.error(error)
			// alert('An error occurred. Check the console for details.');
		}
  }

  return (
		<Button
			sx={{ width: 'max-content', marginX: '5px', marginTop: '2vh' }}
			onClick={() => handleAddToken()}
		>
			Add $SHAKA to wallet
		</Button>
  )
};



function TokenomicsDescription({
  description,
  allocation,
}: {
  description: string;
  allocation: number;
}) {
  return (
    <div >
    <Row spacing={3}>      
    <Typography
    variant="h4"
    padding={1}
    sx={{ fontWeight: "normal", color: DARK_COlORS.caption }}
  >
    {allocation}%
  </Typography>
      <Typography variant="h4" sx={{ color: DARK_COlORS.body }}>
        {description}
      </Typography>

    </Row></div>
  );
}
