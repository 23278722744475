import { Button, Link, Stack, Typography, Tooltip } from "@mui/material";
import {
	MEDIA,
	SHAKAIMG,
	MINT,
	NFT_MINT,
	TWEET_LINK,
	CLAIM_LINKDROP_LINK,
	BASE_EXPLORER,
	DEX_SCREENER,
	DEX_TOOLS
} from '../../constants'
import { Column, Image, MediaStack, Row } from '../common'
import { LIGHT_SHADOW, formatAddress } from '../theme'
import { useHomeScrollContext } from '../../contexts'
import React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import ImageSlider from './ImageSlider'

const slides = [
	{ url: '1.png', title: '#SharetheShaka' },
	{ url: '2.png', title: '#SharetheShaka' },
	{ url: '3.png', title: '#SharetheShaka' },
	{ url: '4.png', title: '#SharetheShaka' },
	{ url: '5.png', title: '#SharetheShaka' },
	{ url: '6.png', title: '#SharetheShaka' },
	{ url: '7.png', title: '#SharetheShaka' },
	{ url: '8.png', title: '#SharetheShaka' },
	{ url: '9.png', title: '#SharetheShaka' },
]
const containerStyles = {
	width: '300px',
	height: '300px',
	margin: '0 auto',
}

const ASPECT_RATIO = 1280 / 1280
const WIDTH = { mobile: 200, desktop: 400 }
export default function Mom() {
	const { aboutRef } = useHomeScrollContext()
	return (
		<Stack ref={aboutRef} alignItems="center" direction={{ xs: 'column', sm: 'row' }}
		spacing={{ xs: 1, sm: 2, md: 4 }}>

<Typography
		variant="subtitle2"
		color="text.primary"
		marginBottom={1}
	>
		Download the SHAKA card & onboard your mom this Mother's Day!
	</Typography>					
	<Button
		sx={{ width: 'max-content', float: "right" }}
		onClick={() => window.open("https://shredding-sassy.ck.page/793f9bf0ae")}
		//disabled={true}
		color="secondary"
		variant="contained"
	>
		Onboard your Mom
	</Button>
		</Stack>
	)
}


