import { Button, Link, Stack, Typography, Tooltip } from "@mui/material";
import {
	MEDIA,
	SHAKAIMG,
	MINT,
	NFT_MINT,
	TWEET_LINK,
	CLAIM_LINKDROP_LINK,
	BASE_EXPLORER,
	DEX_SCREENER,
	DEX_TOOLS
} from '../../constants'
import { Column, Image, MediaStack, Row } from '../common'
import { LIGHT_SHADOW, formatAddress } from '../theme'
import { useHomeScrollContext } from '../../contexts'
import React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import ImageSlider from './ImageSlider'

const slides = [
	{ url: '1.png', title: '#SharetheShaka' },
	{ url: '2.png', title: '#SharetheShaka' },
	{ url: '3.png', title: '#SharetheShaka' },
	{ url: '4.png', title: '#SharetheShaka' },
	{ url: '5.png', title: '#SharetheShaka' },
	{ url: '6.png', title: '#SharetheShaka' },
	{ url: '7.png', title: '#SharetheShaka' },
	{ url: '8.png', title: '#SharetheShaka' },
	{ url: '9.png', title: '#SharetheShaka' },
]
const containerStyles = {
	width: '300px',
	height: '300px',
	margin: '0 auto',
}

const ASPECT_RATIO = 1280 / 1280
const WIDTH = { mobile: 200, desktop: 400 }
export default function About() {
	const { aboutRef } = useHomeScrollContext()
	return (
		<Stack ref={aboutRef}>
			<MediaStack
				sxBelow={{ flexDirection: 'column' }}
				sxAbove={{ alignItems: 'flex-start' }}
				spacing={6}
			>
				{/* //             {images.map((each, index) => (
      //   <div key={index} className="each-slide">
      //       <img src={each} />
      //   </div>))
      // } */}
				<div style={containerStyles}>
					{/* <ImageSlider slides={slides} /> */}
					<ImageSlideshow />
				</div>
				{/* <Image
          variant="fixed-width"
          mobileWidth="100%"
          src={SHAKAIMG}
          width={`${WIDTH.desktop}px`}
          height={`${ASPECT_RATIO * WIDTH.desktop}px`}
        /> 0*/}

				<Column spacing={3} maxWidth={600}>
					<Description />
					
					<Button
						sx={{ width: 'max-content' }}
						onClick={() => window.open(CLAIM_LINKDROP_LINK)}
						//disabled={true}
					>
						Share $SHAKA With Friends
					</Button>

					<Typography
						variant="subtitle2"
						color="text.primary"
						marginBottom={1}
					>
						We've partnered with Linkdrop.io to allow you to share
						$SHAKA with your non crypto native friends & family.
						Wrap some $SHAKA into a claim link and send via social
						media, email or phone!
					</Typography>
					<Typography
						variant="h4"
						color="text.primary"
						marginBottom={1}
					>
						(we're keeping track of shares)
					</Typography>
				</Column>
			</MediaStack>
		</Stack>
	)
}

const bullets = [
	'20 NFT Communities Airdropped Across Base, Polyon, and Ethereum Mainnet',
	'LP burned, 100% of supply distributed',
	'0% TAX on transactions',
]

function Description() {
	return (
		<Column spacing={2}>
			<Typography variant="h1">share the shaka</Typography>

			<Column spacing={0.5}>
				<Typography variant="h4" color="text.primary" marginBottom={1}>
					SHAKA - a universal symbol of love, friendship, and mutual
					respect. Born in Hawaii, embraced by the world as a global
					movement of positivity & solidarity.
				</Typography>

				{/* {bullets.map((b, i) => (
          <Row alignItems="flex-start" spacing={1} key={i}>
            <Typography color="text.disabled">•</Typography>
            <Typography color="text.secondary"> {b} </Typography>
          </Row> /
        ))} */}
			</Column>
		</Column>
	)
}


// images
const images = [
  '1.png', '2.png', '3.png',
  '4.png', '5.png', '6.png',
  '7.png', '8.png', '9.png',
];


const ImageSlideshow: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false); // Tracks if we are currently fading

  useEffect(() => {
    // Set an interval for every 3 seconds
    const interval = setInterval(() => {
      setIsFading(true); // Begin fade out
      setTimeout(() => {
        // Change the image and fade in after half the interval
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsFading(false); // Fade in
      }, 1500); // Halfway through the interval, swap the image
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="image-slideshow">
      {images.map((img, index) => (
        <img
          key={img}
          src={img}
          alt={`Slideshow ${index}`}
          className={`slideshow-image ${index === currentImageIndex ? isFading ? 'fade-out' : 'fade-in' : ''}`}
          style={{ display: index === currentImageIndex ? 'block' : 'none' }}
        />
      ))}
    </div>
  );
};
